<template>
  <v-container fluid>
    <v-sheet>
      <v-row
        dense
        align="end"
      >
        <v-col
          class="ml-md-auto pl-2 pt-2"
          cols="cols"
        >
          <v-btn
            color="primary"
            small
            @click="backOnPressed"
          >{{ $_strings.common.BACK }}
          </v-btn>
        </v-col>
        <v-col
          cols="auto"
          class="ml-md-auto pr-2 pt-2"
        >
          <v-btn
            color="primary"
            small
            @click="updateInvoice"
          >{{$_strings.invoice.TITLE_EDIT}}
          </v-btn>
        </v-col>
      </v-row>
      <br>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        calculate-widths
        :items="itemsInvoice"
        item-key="noInvoice"
        :server-items-length="invoiceListTotalEntry"
        :options.sync="pagination"
        :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      >
        <template v-slot:[`item.shipmentTitle`]={item}>
          <span>{{ item.shipmentTitle }}</span>
          <br/>
          <span class="size-12">{{ item.shipmentCreatedDate }}</span>
        </template>
        <template v-slot:[`item.billedToCompanyName`]={item}>
          <span>{{ item.billedToCompanyName }}</span>
          <br/>
          <span>{{ item.inputBy }}</span>
        </template>
        <template v-slot:[`item.companyAbbreviation`]={item}>
          <span>{{ item.companyAbbreviation }}</span>
          <br/>
          <span>{{ item.inputBy }}</span>
        </template>
        <template v-slot:[`item.transportTypeName`]={item}>
          <span>{{ item.transportTypeName }}</span>
          <br/>
          <span class="size-12">{{ item.transportLicense }}</span>
        </template>
        <template v-slot:[`item.qty`]={item}>
          <v-text-field
            outlined
            dense
            v-mask="{'alias': 'custom-integer'}"
            class="caption input-qty"
            id="qty"
            v-model="item.qty"
            @input.native="() => onChangeQty(item)"
          >
          </v-text-field>
        </template>
        <template v-slot:[`item.priceUnit`]={item}>
          <common-text-field-currency
            outlined
            dense
            prefix="Rp."
            class="caption input-price"
            id="priceUnit"
            v-model="item.priceUnit"
            item-text="priceUnit"
            item-value="id"
            @input.native="() => onChangePrice(item)"
          />
        </template>
        <template v-slot:[`item.totalPrice`]={item}>
          <span>Rp. {{ formatAmount(item.totalPrice) }}</span>
          <br/>
          <span class="size-12">{{ item.costName }}</span>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          <span>
            {{$_strings.invoice.INVOICE_LIST}}
            <span v-if="itemsInvoice.length">
              {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
            </span>
          </span>
        </template>

      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';

import { thousandSeparated } from '../../helper/commonHelpers';

export default {
  name: 'invoice-edit',
  data() {
    return {
      isLoading: false,
      itemsInvoice: [],
      valid: false,
      headers: [
        {
          text: this.$_strings.invoice.ORDER_NO,
          value: 'shipmentTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.SHIPPER_NAME,
          value: 'billedToCompanyName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.REKANAN_3PL,
          value: 'companyAbbreviation',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.ADD_TRANSPORT,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.QUANTITY,
          value: 'qty',
          class: 'white--text primary text-capitalize',
          align: 'center',
        },
        {
          text: this.$_strings.invoice.UNIT_PRICE,
          value: 'priceUnit',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.PRICE,
          value: 'totalPrice',
          class: 'white--text primary text-capitalize',
          align: 'center',
        },
      ],
      invoiceListTotalEntry: 0,
      totalItems: 0,
      shipmentList: [
        {
          id: null,
          shipmentId: null,
          shipmentTitle: null,
        },
      ],
      form: [],
      pagination: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },

    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.fetchInvoiceDetail();
      },
      deep: true,
    },

  },
  methods: {
    refactorForm(event) {
      const priceUnit = event.replace(/[^,\d]/g, '');
      return priceUnit;
    },
    changePrice(event, index) {
      const isNumberOnly = /^\d+$/.test(event.data);
      const tempValue = this.itemsInvoice[index].priceUnit;
      if (isNumberOnly) {
        const priceUnit = `${this.itemsInvoice[index].priceUnit}${event.data}`;
        this.itemsInvoice[index].priceUnit = Number(priceUnit);
      } else if (event.data) {
        event.target.value = Intl.NumberFormat('en-US').format(tempValue);
        this.itemsInvoice[index].priceUnit = tempValue;
      } else {
        this.itemsInvoice[index].priceUnit = event.target.value.replace(/./g, '');
      }
    },
    onChangeQty(item) {
      const { qty } = item;
      const index = this.form.findIndex((e) => e.trxDetailId === item.trxDetailId);
      this.form[index].qty = qty;
      this.itemsInvoice[index].totalPrice = qty * item.priceUnit;
      this.form[index].totalPrice = qty * this.form[index].priceUnit;
    },
    onChangePrice(item) {
      const { priceUnit } = item;
      const index = this.form.findIndex((e) => e.trxDetailId === item.trxDetailId);
      this.form[index].priceUnit = priceUnit;
      this.itemsInvoice[index].totalPrice = this.itemsInvoice[index].priceUnit * this.form[index].qty;
      this.form[index].totalPrice = this.itemsInvoice[index].priceUnit * this.form[index].qty;
    },
    formatDate(date) {
      return dayjs(date).format('DD-MM-YYYY');
    },
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
    fetchInvoiceDetail() {
      this.isLoading = true;
      this.$_services.invoice.invoiceDetail(this.$route.params.invoiceId)
        .then((result) => {
          this.itemsInvoice = result.data;
          this.form = this.itemsInvoice.map((res) => ({
            ...res,
          }));
          this.invoiceListTotalEntry = result.data.length;
        }).finally((e) => {
          this.isLoading = false;
        });
    },
    updateInvoice() {
      this.$root.$loading.show();
      this.$_services.invoice.updateInvoice(this.form)
        .then(() => {
          this.$dialog.notify.success(this.$_strings.invoice.EDIT_MESSAGE_TEXT);
          this.dialog = false;
        })
        .finally(() => {
          this.$root.$loading.hide();
        });
    },
    backOnPressed() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-chip.v-size--default {
      font-size: 12px;
      margin-top: 3px;
  }
  .order-checked{
    font-size: 14px;
    color: #4d4f5c;
  }
  .size-12 {
    font-size: 12px;
  }
  .input-price{
    top: 15px;
    width: 150px;
    .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
      border: none;
    }
  }
  .input-qty{
    top: 15px;
    width: 50px;
    .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
      border: none;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  .input-price ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .input-qty ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
      -moz-appearance: textfield;
  }
</style>
