var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',[_c('v-row',{attrs:{"dense":"","align":"end"}},[_c('v-col',{staticClass:"ml-md-auto pl-2 pt-2",attrs:{"cols":"cols"}},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.backOnPressed}},[_vm._v(_vm._s(_vm.$_strings.common.BACK)+" ")])],1),_c('v-col',{staticClass:"ml-md-auto pr-2 pt-2",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.updateInvoice}},[_vm._v(_vm._s(_vm.$_strings.invoice.TITLE_EDIT)+" ")])],1)],1),_c('br'),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"calculate-widths":"","items":_vm.itemsInvoice,"item-key":"noInvoice","server-items-length":_vm.invoiceListTotalEntry,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.shipmentTitle",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.shipmentTitle))]),_c('br'),_c('span',{staticClass:"size-12"},[_vm._v(_vm._s(item.shipmentCreatedDate))])]}},{key:"item.billedToCompanyName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.billedToCompanyName))]),_c('br'),_c('span',[_vm._v(_vm._s(item.inputBy))])]}},{key:"item.companyAbbreviation",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.companyAbbreviation))]),_c('br'),_c('span',[_vm._v(_vm._s(item.inputBy))])]}},{key:"item.transportTypeName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transportTypeName))]),_c('br'),_c('span',{staticClass:"size-12"},[_vm._v(_vm._s(item.transportLicense))])]}},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'custom-integer'}),expression:"{'alias': 'custom-integer'}"}],staticClass:"caption input-qty",attrs:{"outlined":"","dense":"","id":"qty"},nativeOn:{"input":function($event){return (function () { return _vm.onChangeQty(item); })($event)}},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", $$v)},expression:"item.qty"}})]}},{key:"item.priceUnit",fn:function(ref){
var item = ref.item;
return [_c('common-text-field-currency',{staticClass:"caption input-price",attrs:{"outlined":"","dense":"","prefix":"Rp.","id":"priceUnit","item-text":"priceUnit","item-value":"id"},nativeOn:{"input":function($event){return (function () { return _vm.onChangePrice(item); })($event)}},model:{value:(item.priceUnit),callback:function ($$v) {_vm.$set(item, "priceUnit", $$v)},expression:"item.priceUnit"}})]}},{key:"item.totalPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("Rp. "+_vm._s(_vm.formatAmount(item.totalPrice)))]),_c('br'),_c('span',{staticClass:"size-12"},[_vm._v(_vm._s(item.costName))])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.invoice.INVOICE_LIST)+" "),(_vm.itemsInvoice.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }